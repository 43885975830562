.calendar-container,
.calendar,
.calendar-header,
td,
tr {
    padding: 0;
    margin: 0;
}

.calendar-container {
    width: 100%;
}

.calendar {
    background-color: white;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid #d9d9d9;
    width: 100%;
    /*color: black;*/
}

.calendar-header {
    border-bottom: 1px solid #d9d9d9;
    text-align: left;
}

.calendar-header td {
    border-spacing: 0;
    padding: 2em;
}

.day,
.empty-slot,
.week-day {
    text-align: center;
}

.week-day {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1em;
    border: 1px solid #d9d9d9;
    /*background-color: #eeeeee;*/
    padding: 0.5em;
}

.day {
    border: 1px solid #d9d9d9;
    padding-bottom: 3em;
    vertical-align: top;
}

.day-number {
    font-size: 2.5em;
    font-weight: bold;
    cursor: pointer;
}

.obchod {
    font-size: 0.8em;
    padding: 0.3em;
}

.holiday {
    color: red;
}

.solemnity {
    font-weight: 900;
    text-transform: uppercase;
}

.feast {
    font-weight: 900;
}

.secular {
    font-style: italic;
}

.current-day {
    border: 2px solid #4285f4;
    /*border-radius: 2%;
    color: white;*/
}

.selected-day {
    background-color: yellow;
    border-radius: 2%;
    /*color: black;*/
}

.nav-month {
    position: absolute;
    top: 0.6em;
    font-size: 0.6em;
    right: 3px;
}

.prev {
}

.next {
}

.editor-year {
    max-width: 3.6em;
    font-size: 3.1em;
}

.editor-month {
    font-size: 3.1em;
}

.label-month,
.label-year {
    font-size: 4.3em;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.2em;
}

.event {
    border: 1px solid #d9d9d9;
    cursor: pointer;
    text-align: left;
    margin: 0.7em;
    padding: 0.4em;
}

.event-green {
    border: 1px solid #7cb305;
    background-color: #8dc416;
    color: #ffffff;
}

.event-blue {
    border: 1px solid #3595ee;
    background-color: #46a6ff;
    color: #ffffff;
}
