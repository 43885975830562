.SortableList {
    position: relative;
    z-index: 0;
    background-color: #F3F3F3;
    border: 1px solid #EFEFEF;
    border-radius: 3px;
    outline: none;

    width: auto;
    /*height: 600px;*/
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
}
.SortableItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background-color: #FFF;
    border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    /*font-weight: 500;*/
}
.SortableHelper {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
    z-index: 1001;
    padding-right: 24px;
    padding-left: 24px;
}
.SortableMeta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    /*font-size: 0;*/
}
.SortableAction {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-left: 48px;
    padding: 0;
    font-size: 0;
    list-style: none;
}
.SortableAction > li:first-child {
    padding-left: 0;
}
.SortableAction > li {
    position: relative;
    display: inline-block;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
}
.SortableHandle {
    cursor: pointer;
}
