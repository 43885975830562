#root {
    height: 100%;
}

.App {
    padding: 20px;
}

.logo {
    height: 16px;
}

.selectable {
    cursor: pointer;
}

.lyrics-prefix {
    margin-right: 0.5em;
}

.lyrics-chords {
    float: right;
    font-weight: bold;
}
